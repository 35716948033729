import React from "react";
import { Container, Row } from "react-bootstrap";
import ProjectFrame from "../../components/ProjectFrame";
import oasis from "../../assets/images/projects-images/oasis.png";
import tcf from "../../assets/images/projects-images/tcf.png";

const OurWork = () => {
  const projects = [
    {
      title: "Oasis",
      desc: "Auto Shop",
      link: "https://www.oasisautoparts.co.ke/",
      image: oasis,
    },
    {
      title: "TCF",
      desc: "E-Shop",
      link: "https://tcfstore.com/",
      image: tcf,
    },
  ];

  return (
    <section className="ourwork" id="ourwork">
      <Container>
        <Row>
          <div className="title_modern">
            <h2>Our Work?</h2>
          </div>
        </Row>
        <br />
        <Row>
          {projects.map((item, index) => {
            return <ProjectFrame key={index} project={item} />;
          })}
        </Row>
      </Container>
    </section>
  );
};

export default OurWork;
