import React, { useState } from "react";
import { Col, Ratio } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";

const ProjectFrame = ({ project }) => {
  const [show, setShow] = useState(false);

  return (
    <Col xs={12} md={4} xl={4} className="project_box">
      <TrackVisibility>
        {({ isVisible }) => (
          <img
            src={project.image}
            className={isVisible ? "animate__animated animate__pulse" : ""}
            alt=""
            onClick={() => {
              setShow(true);
              console.log("clicked-rue");
            }}
          />
        )}
      </TrackVisibility>

      <br />
      <h5>
        <a href={project.link} target="_blank" rel="noreferrer">
          {project.title}
        </a>
      </h5>
      <p>{project.desc}</p>

      <div
        className={show ? "i_frame_body i_frame_show" : "i_frame_body"}
        // style={{ display: show ? "block" : "none" }}
        onClick={() => {
          setShow(false);
          console.log("clicked");
        }}
      >
        <X
          className="i_frame_close"
          size={40}
          onClick={() => {
            setShow(false);
            console.log("clicked");
          }}
        />
        <Ratio aspectRatio={"16x9"}>
          <iframe
            title={project.title}
            className="i_frame_spec"
            src={project.link}
            allowFullScreen
          ></iframe>
        </Ratio>
      </div>
    </Col>
  );
};

export default ProjectFrame;
