import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo2 from "../assets/images/milescorplogo2.png";
import number1 from "../assets/images/number1.png";
import premium from "../assets/images/premium-quality.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <Container>
        <Row className="top_part">
          <Col xs={12} md={4} xl={4}>
            <img src={logo2} alt="" />
          </Col>
          <Col
            xs={12}
            md={4}
            xl={4}
            style={{
              textAlign: "start",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h3>Reach Us</h3>
            <p>
              <a href="mailto:mileslemi@gmail.com">mileslemi@gmail.com</a>{" "}
              <span
                style={{ borderRight: "1px solid gray", margin: "0 10px" }}
              ></span>{" "}
              +(254)706 803 305
            </p>
          </Col>
          <Col xs={12} md={4} xl={4} className="badges">
            <img src={number1} alt="" />
            <img src={premium} alt="" />
          </Col>
        </Row>
        <Row className="bottom_part">
          <Col xs={12} md={6} xl={6}>
            <p>&copy; {year} MilesCorp. All Rights Reserved. </p>
          </Col>
          <Col xs={12} md={6} xl={6} style={{ textAlign: "start" }}>
            <ul className="row">
              <li className="col-md-3">
                <a href="#">Company</a>
              </li>
              <li className="col-md-3">
                <a href="#services">Services</a>
              </li>
              <li className="col-md-3">
                <a href="#ourwork">Our Work</a>
              </li>
              <li className="col-md-3">
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
