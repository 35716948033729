import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import team from "../../assets/icons/team-svgrepo.svg";
import quality from "../../assets/icons/quality-3-svg.svg";
import vendor from "../../assets/icons/cart-svg.svg";

const Services = () => {
  return (
    <section className="services" id="services">
      <Container>
        <div className="title_modern">
          <h2>What We Offer</h2>
        </div>

        <Row>
          <Col xs={12} md={6} xl={6}>
            <h4>Web Development</h4>
            <p>
              We are highly acclaimed web design and development company
              renowned for its exceptional solutions tailored to businesses'
              unique needs. Our team of talented designers and developers
              collaborate closely with clients, ensuring that every website
              embodies client's vision and goals. From intuitive user interfaces
              to responsive designs, MilesCorp excels in crafting visually
              captivating and functional websites. We are the ideal partner to
              elevate businesses' online presence and maximize their digital
              potential.
            </p>
          </Col>
          <Col xs={12} md={6} xl={6}>
            <h4>App Development</h4>
            <p>
              Dynamic and forward-thinking app development company, specializing
              in crafting custom solutions that meet the unique needs of
              businesses. Our dedicated team of developers and designers work
              closely with clients, ensuring that every aspect of the app aligns
              with their vision and objectives. From conceptualization to
              deployment, MilesCorp prioritizes user experience, functionality,
              and scalability.
            </p>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Col xs={12} md={6} xl={6}>
            <h4>Desktop Development</h4>
            <p>
              Our team of adept developers and designers collaborates closely
              with clients to create desktop applications that precisely cater
              to their specific requirements and objectives. From
              conceptualization to deployment, MilesCorp prioritizes user
              experience, functionality, and scalability, ensuring that every
              desktop application delivers exceptional performance.
            </p>
          </Col>
        </Row>
        <br />
        <div className="title_modern">
          <h2>Why Work With Us?</h2>
        </div>
        <Row className="why_work_with_us">
          <Col xs={12} md={4} xl={4}>
            <img src={quality} alt="" />
            <div>
              <h5>Premium Quality</h5>
              <p>
                Our motto is to go the extra mile, reaching above and beyond to
                meet customer's need. All at an affordable price.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} xl={4}>
            <img src={team} alt="" />
            <div>
              <h5>A-Team</h5>
              <p>
                We have an exceptional team, available 24/7. These industry
                veterans are always looking to outperform themselves, making
                sure you get only what's best.
              </p>
            </div>
          </Col>
          <Col xs={12} md={4} xl={4}>
            <img src={vendor} alt="" />
            <div>
              <h5>One-Stop-Vendor</h5>
              <p>
                From domain registration, hosting, intergration, and even
                training; we'll make sure you are more that aquinted with the
                system.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
